<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { onBeforeUnmount, onMounted, ref } from "vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import { debounce } from "lodash-es";

export type DropdownVariant = "relative" | "centered" | "right" | "absolute";
export type DropdownSize = "auto" | "small";

type Props = {
    caret?: boolean;
    maxHeight?: boolean;
    variant?: DropdownVariant | DropdownVariant[];
    size?: DropdownSize;
    offset?: string;
};
const {
    caret = true,
    maxHeight = false,
    variant = undefined,
    size = undefined,
    offset = undefined,
} = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "",
    variants: {
        variant: {
            relative: "relative",
            absolute: "absolute",
            centered: "",
            right: "",
        },
        size: {
            auto: "",
            small: "",
        },
    },
};
const { aClass } = installAntlerComponent(
    "dropdown",
    { variant, size },
    classes,
);

const contentClasses: AntlerClasses<Props> = {
    base: "absolute top-[calc(100%-2px)] left-0 z-30 min-w-[240px] p-2 bg-white border border-slate-300 ring-opacity-5 drop-shadow-lg origin-top-left rounded-md overflow-auto bg-white",
    variants: {
        variant: {
            relative: "",
            centered: "-translate-x-1/2",
            right: "",
            absolute: ""
        },
        size: {
            auto: "",
            small: "",
        },
    },
};
const { aClass: aContentClass } = installAntlerComponent(
    "dropdown-content",
    { variant, size },
    contentClasses,
);

const openMenu = ref(false);
const dropdown = ref();
const menu = ref();

const closeMenu = () => {
    openMenu.value = false;
};

onClickOutside(dropdown, () => {
    openMenu.value = false;
});

const setHeight = () => {
    if (
        typeof window !== "undefined" &&
        dropdown.value &&
        maxHeight &&
        menu.value
    ) {
        const dropdownWrapper = dropdown.value;
        const menuEl = menu.value;
        const dropdownWrapperRect = dropdownWrapper.getBoundingClientRect();

        menuEl.style.maxHeight = visualViewport
            ? visualViewport.height - dropdownWrapperRect.bottom + "px"
            : null;
    }
};

// lifecycle
onMounted(() => {
    if (typeof window !== "undefined" && maxHeight && window.visualViewport) {
        setHeight();

        window.visualViewport.addEventListener(
            "resize",
            debounce(setHeight, 100),
        );
    }
});
onBeforeUnmount(() => {
    if (typeof window !== "undefined" && maxHeight && window.visualViewport) {
        window.visualViewport.removeEventListener(
            "resize",
            debounce(setHeight),
            100,
        );
    }
});
</script>

<template>
    <div
        ref="dropdown"
        :class="aClass()"
        @keydown.escape="closeMenu"
    >
        <div
            class="flex items-center"
            @click="!caret ? (openMenu = !openMenu) : null"
        >
            <slot name="toggle" />

            <AppButton
                v-if="caret"
                :icon="openMenu ? 'caret-up' : 'caret-down'"
                icon-size="small"
                size="small"
                icon-color="none"
                variant="ghost round"
                @click="openMenu = !openMenu"
            />
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div
                v-show="openMenu"
                ref="menu"
                :class="
                    [aContentClass({
                        'right-0 left-auto origin-top-right':
                            variant && variant.includes('right'),
                        '-translate-x-1/2':
                            variant && variant.includes('centered'),
                        'min-w-fit': size && size.includes('auto'),
                        'min-w-[180px]': size && size.includes('small'),
                        'min-w-[320px] md:min-w-[380px]':
                            size && size.includes('large'),
                    }), offset]
                "
            >
                <slot :close="closeMenu" />
            </div>
        </transition>
    </div>
</template>
