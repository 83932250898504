<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
    TooltipContent,
    type TooltipContentEmits,
    type TooltipContentProps,
    TooltipPortal,
    useForwardPropsEmits,
} from "radix-vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

defineOptions({
    inheritAttrs: false,
});

const props = withDefaults(
    defineProps<TooltipContentProps & { class?: HTMLAttributes["class"] }>(),
    {
        sideOffset: 4,
    },
);

const emits = defineEmits<TooltipContentEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const classes = {
    base: "z-50 overflow-hidden rounded-md border bg-black px-3 py-1.5 text-xs rounded-sm text-white shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
};
const { aClass } = installAntlerComponent("tooltip", {}, classes);
</script>

<template>
    <TooltipPortal>
        <TooltipContent
            v-bind="{ ...forwarded, ...$attrs }"
            :class="aClass()"
        >
            <slot />
        </TooltipContent>
    </TooltipPortal>
</template>
