<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import Logo from "@/Components/Icons/Logo.vue";
export type ImageVariant = "card" | "post";

type Props = {
    src?: string;
    alt?: string;
    placeholder?: boolean;
    transparentPlaceholder?: boolean;
    variant?: ImageVariant;
};
const { src, placeholder = false, variant = undefined } = defineProps<Props>();

// classes
const classes: AntlerClasses<Props> = {
    base: "max-h-full",
    variants: {
        variant: {
            card: "rounded min-h-[40px]",
            post: "max-h-[320px] object-contain",
        },
    },
};
const { aClass } = installAntlerComponent("image", { variant }, classes);

const placeholderClasses: AntlerClasses<Props> = {
    base: "opacity-20 max-h-[80%] w-[80%]",
    variants: {
        variant: {
            card: "min-h-[40px]",
            post: ""
        },
    },
};
const { aClass: placeholderClass } = installAntlerComponent(
    "image-placeholder",
    { variant },
    placeholderClasses,
);
</script>

<template>
    <img
        v-if="src"
        :src="src"
        :alt="alt"
        :class="aClass()"
    />
    <div
        v-else-if="placeholder"
        :class="{
            'flex items-center justify-center grow bg-slate-50 h-full rounded p-1':
                !transparentPlaceholder,
        }"
    >
        <Logo :class="placeholderClass()" />
    </div>
</template>
